/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // Fancy headers
        $('.main-title').each(function() {
          var $this = $(this);
          var regex = /^(The |In the )/i;
          var string = $this.text();
          if (regex.test(string)) {
            $this.addClass('has-subheader');
            $this.text(string.replace(regex, ''));
            var found = string.match(regex);
            $this.before("<h2 class='subheader'>" + found[0].trim() + "</h2>");
          }
        });

        $bookSlider = $('.book-slider');

        function changeLink(link) {
          $('.mobile-view-book').attr('href', link);
        }

        $bookSlider.on('init', function(event, slick) {
          var currentSlide = $bookSlider.find('.slick-current');
          var link = currentSlide.data('link');
          changeLink(link);
        });

        $bookSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
          $bookSlider.find('.book:not(.slick-cloned)').each(function() {
            var $this = $(this);
            if (nextSlide === $this.data('slick-index')) {
              var link = $this.data('link');
              changeLink(link);
            }
          });
        });

        $bookSlider.slick({
          arrows: false,
          centerMode: true,
          variableWidth: true
        });

        document.addEventListener("touchstart", function() {},false);
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $( ".nav-toggle").on( "click", function(e) {
          e.preventDefault();
          $("nav:not(.woocommerce-pagination)").toggleClass( "nav-show");
          $("header").toggleClass("open");
          $("body, html").toggleClass('no-scroll');
          if ($("a.nav-toggle").text() === "MENU") {
            $(this).text('CLOSE');
          } else {
            $(this).text('MENU');
          }
        });

        $( ".nav>li" ).each(function(index) {
          var menuNum = (index + 1) + ".";
          if (index < 9) { menuNum = "0" + menuNum; }
          $(this).before( '<span class="menu-num">'+menuNum+'</span>' );
        });

        // Opens order button
        $( '.order-btn' ).on( "click", function() {
          $( '.order-btn' ).removeClass('active');
          $( '.store-links-container' ).removeClass('is_visible');
          var $this = $(this);
          var $buttontext = $this.data('text');
          if ($this.html() === $buttontext) {
             $this.text('Close');
             $this.addClass('active');
             $this.siblings().addClass( 'is_visible' );
          } else {
            $this.text($buttontext);
          }
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        // ScrollMagic
        if ($('.main-callout').length === 0) {
          $('.home').addClass('no-animation');
        } else {
          var controller = new ScrollMagic.Controller();

          var scene1 = new ScrollMagic.Scene({
            duration: 150
          }).setTween(".full-logo", {top: 0, opacity: 0, ease: Power0.easeNone})
            .addTo(controller);

          var scene2 = new ScrollMagic.Scene({
            offset: 100,
            duration: 200
          }).setTween("header", {backgroundColor: 'rgba(255,255,255,1)', height: 80, ease: Power0.easeNone})
            .addTo(controller);

          var scene3 = new ScrollMagic.Scene({
            offset: 100,
            duration: 200
          }).setTween(".logo", {opacity: 1, marginTop: 28, display: 'block', ease: Power0.easeNone})
            .addTo(controller);

          var scene4 = new ScrollMagic.Scene({
            offset: 100,
            duration: 200
          }).setTween(".nav-toggle", {color: "#000", paddingTop: 26, paddingBottom: 26, ease: Power0.easeNone})
            .addTo(controller);
        }

        // countdown
        $('.countdown').each(function() {
          var $this = $(this), release = $this.data('release');
          if (release) {
            $this.countdown(release, function(event) {
              if (event.type === 'finish') {
                $this.css('display', 'none');
                return;
              }
              $this.html(event.strftime(
                '<span class="number">%-D</span> <span class="text">day%!D</span> ' +
                '<span class="number">%-H</span> <span class="text">hour%!H</span>' +
                '<span class="number">%-M</span> <span class="text">minute%!M</span> ' +
                '<span class="number">%-S</span> <span class="text">second%!S</span>'));
            });
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'page_template_template_books': {
      init: function() {
        $('.slider').slick({
          dots: true, 
        });

        $('.slideout').on('click', function() {
          $(this).animate({opacity: 0}, 450);
          $('.book-list').animate({right: 0}, 450);
        });

        $('.slidein').on('click', function() {
          $('.slideout').animate({opacity: 1}, 450);
          $('.book-list').animate({right: '-50%'}, 450);
        });
      }
    },
    'page_template_template_preview': {
      init: function() {
        
        $("body").keydown(function(e) {
          if(e.keyCode == 37) {
            $('.excerpt-slider').slick("slickNext");
          }
          else if(e.keyCode == 39) {
            $('.excerpt-slider').slick("slickPrev");
          }
        });

        $('.excerpt-slider').slick({
          prevArrow: $('.excerpt-slider__arrows__prev'),
          nextArrow: $('.excerpt-slider__arrows__next'),
          swipe: false,
          touchMove: false,
          draggable: false, 
        });

        $('.nav-item').on('click', function() {
          var i = $(this).index();
          $('.excerpt-slider').slick("slickGoTo", i);
        });
      }
    },
    'single_recipes': {
      init: function() {
        $( ".preparation-list>li" ).each(function() {
          $(this).prepend( '<span class="recipe-num">'+ ($(this).index() +1) + "." + '</span>' );
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // Sorting
  $(window).on('load', function() {
    $(".post-list__item:not(.callout)").equalHeight();
    $container = $('.post-list');

    // this shuffle's .post-lists
    // $container.shuffle({
    //   itemSelector: '.post-list__item',
    //   speed: 450
    // });
    $currentSort = 'all';
    $('.item-sorting li').on('click', function() {
      var $this = $(this);
      var $category = $this.data('category');

      if ($currentSort === $category) {
        $this.removeClass('selected');
        $currentSort = 'all';
        $container.shuffle('shuffle', function($el, shuffle) {
          return true;
        });
      } else {
        $this.addClass('selected');
        $this.siblings('li').removeClass('selected');
        $currentSort = $category;
        $container.shuffle('shuffle', function($el, shuffle) {
          var $categories = $el.data('groups').split(' ');
          if ($.inArray($category, $categories) !== -1) {
            return true;
          } else {
            return false;
          }
        });
      }
    });
  });


   // equal-height
  $.fn.equalHeight = function() {
    var maxHeight = 0;
    return this.each(function(index, box) {
        var boxHeight = $(box).height();
        maxHeight = Math.max(maxHeight, boxHeight);
    }).height(maxHeight);
  };

  // image magnify
document.addEventListener("DOMContentLoaded", function() {

  function setZoomAttributes(image, zoom_x, zoom_y, scale){
    image.style.setProperty('--zoom-x', '-' + zoom_x + '%');
    image.style.setProperty('--zoom-y', '-' + zoom_y + '%');
    image.style.setProperty('--zoom-scale', scale);
  }

  var images = document.querySelectorAll('[data-component="image-magnify"]');
  
  if (images && window.innerWidth > 960){
    images.forEach(function(element, index) {
      
      var zoom_scale = element.getAttribute('data-zoom-scale');
      var zoom_exp = 100 + (parseInt(zoom_scale) * 100);
      var image = element.querySelector('img');

      if ( !image ) {return;}
      image.addEventListener('mousemove', function (e) {
          var x = e.pageX - this.offsetLeft,
              y = e.pageY - this.offsetTop,
              zoom_x = (x / this.width * 0.25) * zoom_exp,
              zoom_y = (y / this.height * 0.25) * zoom_exp;
          setZoomAttributes(this, zoom_x, zoom_y, zoom_scale);          
      });
  
      image.addEventListener('mouseleave', function (e) {
        setZoomAttributes(this, 0, 0, 1.0);
      });
    });
  } else if (images && window.innerWidth < 960){
    images.forEach(function(element, index) {
      var image = element.querySelector('img');
      if ( !image ) {return;}

      image.addEventListener('click', function (e) {
        if ( !$(this).hasClass('zoom-image')){
          $(this).addClass('zoom-image');
          var imgWidth = $(this).width() / 3;
          var imgHeight = $(this).height() / 3;
          $(this).parent().scrollTop(imgHeight).scrollLeft(imgWidth);
        } else {
          $(this).removeClass('zoom-image');
        }
      });
    });
  }
});

})(jQuery); // Fully reference jQuery after this point.


